import { signalOrgNodesInputChanged } from "../../../../common/webpack/shared/const/occupationDependingOnOrgNodesConstants";

document.addEventListener("DOMContentLoaded", () => {
  const occupationDependingOnOrgNodes = document.querySelectorAll(".occupation_depending_on_org_nodes");
  if (!occupationDependingOnOrgNodes.length) return;
  const helpText = document.getElementById("hint_id_occupation");
  if (!helpText) return;
  helpText.style.display = "none";
  document.querySelectorAll("[id='id_org_nodes']").forEach((el) => {
    el.addEventListener(signalOrgNodesInputChanged, (e) => {
      // only show occupation help text when org nodes have been chosen
      helpText.style.display = e.detail ? "block" : "none";
    });
  });
});
